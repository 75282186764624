import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { isMobile } from "react-device-detect";

interface UseSite {
  setMenu: (menu: boolean) => void;
  menu: boolean;
  mobile: boolean;
  config: Config;

  modal: Modais;
  setModal: (modais: Modais) => void;
  handleClose(close: keyOf<Modais>): void;
  handleOpen(close: keyOf<Modais>): void;
}

interface UseSiteProviderProps {
  children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface Modais {
  menu: boolean;
  orcamento: boolean;
}

export interface DataConfig {
  data: Config;
}

export interface Config {
  email: string;
  celular: string;
  telefone: string;
  endereco: Endereco;
  social: Social;
  gtm: Gtm;
}

export interface Endereco {
  cep: string;
  logradouro: string;
  numero: string;
  bairro: string;
  complemento: null | string;
  cidade: string;
  estado: string;
}

export interface Social {
  facebook: null | string;
  instagram: null | string;
  twitter: null | string;
  youtube: null | string;
  linkedin: null | string;
  tiktok: null | string;
}

export interface Gtm {
  head: null | string;
  body: null | string;
}

const UseSiteContext = createContext({} as UseSite);

type keyOf<T> = keyof T;

export function UseSiteProvider(props: UseSiteProviderProps) {
  const { children } = props;
  const token = process.env.NEXT_PUBLIC_API_KEY;
  const [menu, setMenu] = useState(false);
  const [config, setConfig] = useState({} as Config);
  const [mobile, setMobile] = useState(false);
  const [modal, setModal] = useState<Modais>({
    menu: false,
    orcamento: false,
  });

  function handleClose(close: keyOf<Modais>) {
    setModal({
      ...modal,
      [close]: false,
    });
  }

  function handleOpen(open: keyOf<Modais>) {
    setModal({
      ...modal,
      [open]: true,
    });
  }

  const getConfig = useCallback(async () => {
    try {
      const response = await axios.get<DataConfig>("/api/config");

      setConfig(response.data.data);
    } catch (error) {
      setConfig({} as Config);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  return (
    <UseSiteContext.Provider
      value={{
        setMenu,
        config,
        menu,
        mobile,
        modal,
        setModal,
        handleClose,
        handleOpen,
      }}
    >
      {children}
    </UseSiteContext.Provider>
  );
}

export function useSite() {
  const context = useContext(UseSiteContext);

  return context;
}
