import React, { Suspense } from "react";

import {
  CaravelaBanner,
  CaravelaBannerProps,
  CaravelaHistoria,
  CaravelaHistoriaProps,
  CaravelaEmpresaProps,
  CaravelaProjetos,
  CaravelaProdutosProps,
  CaravelaBlog,
  CaravelaBlogProps,
} from "cms-caravela";

import { GetStaticProps } from "next";
import Head from "next/head";

import dynamic from "next/dynamic";
import {
  DataModulosBanners,
  BannerProps,
  EquipeProps,
  DataModuloEquipe,
  DataModuloServicos,
  ServicosProps,
  DataModulosPortifolio,
  ProdutosProps,
  DataModulosBlog,
  BlogProps,
} from "../types";

import { useSite } from "../hooks/useSite";
import axios from "axios";

const CaravelaEmpresa = dynamic(
  () => import("cms-caravela").then((mod) => mod.CaravelaEmpresa),
  {
    ssr: false,
  }
);

const loading = false;

const token = process.env.NEXT_PUBLIC_API_KEY;
const time = process.env.NEXT_PUBLIC_TIME;

interface HomeProps
  extends BannerProps,
    EquipeProps,
    ServicosProps,
    ProdutosProps,
    BlogProps {}

const mockBanner = {
  title: "Mar calmo nunca fez bom marinheiro!",
  subTitle: "Algumas agências até tem a coragem para vencer  desafios.",
  description: "Nós temos o hábito!",
  backGroundImg: {
    src: "",
    alt: "",
  },
  btnImgEsq: "",
  btnImgDir: "",
  btnOnClick: () => console.log("opa"),
  loading: true,
} as CaravelaBannerProps;

const mockHistoria = {
  img: {
    src: "",
    alt: "",
  },
  title: "A agência",
  subTitle: "Nossos marujos!",
  descriptions: [
    "A Caravela nasceu há dez anos com um propósito: fazer diferente no seu mercado. Isso significa estar conectada ao que há de mais atual na área de comunicação e marketing digital. Sabemos por que sua empresa deve estar na Internet, sabemos como deve estar para se  diferenciar. Afinal, navegar é preciso.",
    "Nesse período nos especializamos em atuar nos quatro pilares da Comunicação Integrada no Marketing: Marketing Digital, Assessoria de Imprensa, Comunicação Interna, Publicidade e Propaganda.",
  ],
  imgSetaBaixo: {
    src: "",
    alt: "",
  },
  seta: {
    src: "",
    alt: "",
  },
  loading: true,
  cards: [
    {
      img: {
        src: "",
        alt: "",
      },
      name: "Fuluno",
      functionWork: "Desenvolvedor",
      loading: true,
    },
  ],
} as CaravelaHistoriaProps;

const mockEmpresas = {
  title: "O que podemos fazer pela sua empresa!",
  cards: [
    {
      title: "Digital Marketing",
      description:
        "Caravela was born ten years ago with one purpose: to make a difference in your market. This means being connected to what is most up to date.",
      img: {
        src: "",
        alt: "",
      },
      loading: true,
    },
  ],

  loading: true,
  mobile: false,
} as CaravelaEmpresaProps;

const mockProjetos = {
  title: "Veja nossos projetos",
  loading: true,
  mobile: false,
  cards: [
    {
      img: {
        src: "",
        alt: "",
      },
      title: "Fazenda Santa Cruz",
      description:
        "A Caravela nasceu há dez anos com um propósito: fazer diferente no seu mercado. Isso significa estar conectada.",
      seta: {
        img: {
          src: "",
          alt: "",
        },
      },
      link: {
        href: "",
      },
      loading: true,
    },
  ],
  setaBaixo: "",
  seta: {
    src: "",
    alt: "",
  },
} as CaravelaProdutosProps;

const mockBlog = {
  icone: "",
  seta: {
    src: "",
    alt: "",
  },
  loading: true,
  cards: [
    {
      img: {
        src: "",
        alt: "",
      },
      title: "Qual a melhor forma de usar o app Figma em projetos.",
      description:
        "A Caravela nasceu há dez anos com um propósito: fazer diferente no seu mercado. Isso significa.",
      tempoLeitura: {
        date: "12.08.2020",
        time: "5 min",
        img: "",
      },
      tag: {
        title: "Desgin",
      },
      link: {
        href: "",
      },
      loading: true,
    },
  ],
  mobile: false,
} as CaravelaBlogProps;

export default function Home(props: HomeProps) {
  const { banner, equipe, servicos, produtos, blog } = props;
  const { mobile, modal, setModal } = useSite();

  return (
    <>
      <Head>
        <title>Caravela</title>
        <meta property="og:title" content={banner.title} />
        <meta
          name="og:description"
          content={`${banner.subTitle} ${banner.description}`}
        />
        <meta property="og:image:alt" content="Banner principal" />
      </Head>

      <Suspense fallback={<CaravelaBanner {...mockBanner} />}>
        <CaravelaBanner
          {...banner}
          btnOnClick={() => {
            setModal({
              ...modal,
              menu: false,
              orcamento: true,
            });
          }}
        />
      </Suspense>

      <Suspense fallback={<CaravelaHistoria {...mockHistoria} />}>
        <CaravelaHistoria {...equipe} />
      </Suspense>

      <Suspense
        fallback={<CaravelaEmpresa {...mockEmpresas} mobile={mobile} />}
      >
        <CaravelaEmpresa {...servicos} mobile={mobile} />
      </Suspense>

      <Suspense
        fallback={<CaravelaProjetos {...mockProjetos} mobile={mobile} />}
      >
        <CaravelaProjetos {...produtos} mobile={mobile} />
      </Suspense>

      <Suspense fallback={<CaravelaBlog {...mockBlog} mobile={mobile} />}>
        <CaravelaBlog {...blog} mobile={mobile} />
      </Suspense>
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const mobile = false;

  const responseBanner = await axios.post<DataModulosBanners>(
    "https://cms.octa.plus/api/modulos/banners",
    {
      token,
    }
  );

  const responseEquipe = await axios.post<DataModuloEquipe>(
    "https://cms.octa.plus/api/modulos/equipe",
    {
      token,
    }
  );

  const responseServicos = await axios.post<DataModuloServicos>(
    "https://cms.octa.plus/api/modulos/servicos",
    {
      token,
    }
  );

  const responsePortifolio = await axios.post<DataModulosPortifolio>(
    "https://cms.octa.plus/api/modulos/portifolio",
    {
      token,
    }
  );

  const responseBlog = await axios.post<DataModulosBlog>(
    "https://cms.octa.plus/api/modulos/blog",
    {
      token,
    }
  );

  const text = responseBanner.data.data[0]?.subtitulo.split(".") || "";

  return {
    props: {
      banner: {
        title: "Mar calmo nunca fez bom marinheiro!",
        subTitle: text[0] ? text[0] : "",
        description: text[1] ? text[1] : "",
        backGroundImg: {
          src: responseBanner?.data?.data[0]?.imagem_desktop || "",
          alt: "banner principal",
        },
        btnImgEsq: "/img/icone/x-orcamento.svg",
        btnImgDir: "/img/icone/seta-dir.svg",
        loading: false,
      } as CaravelaBannerProps,

      equipe: {
        cards: responseEquipe?.data?.data.map((item) => {
          return {
            img: {
              src: item?.imagem || "",
              alt: item?.nome || "",
            },
            name: item?.nome || "",
            functionWork: item?.descricao || "",
            loading: false,
          };
        }),
        descriptions: [
          "A Caravela nasceu há dez anos com um propósito: fazer diferente no seu mercado. Isso significa estar conectada ao que há de mais atual na área de comunicação e marketing digital. Sabemos por que sua empresa deve estar na Internet, sabemos como deve estar para se  diferenciar. Afinal, navegar é preciso.",
          "Nesse período nos especializamos em atuar nos quatro pilares da Comunicação Integrada no Marketing: Marketing Digital, Assessoria de Imprensa, Comunicação Interna, Publicidade e Propaganda.",
        ],
        title: "A agência",
        img: {
          src: "/img/icone/marujos.svg",
          alt: "seta baixo verde",
        },
        imgSetaBaixo: {
          src: "/img/icone/seta-baixo.svg",
          alt: "seta baixo",
          width: 35,
          height: 50,
        },
        seta: {
          src: "/img/icone/seta-dir-card.svg",
          alt: "seta",
          width: 16,
          height: 16,
        },
        subTitle: "Nossos marujos!",
        loading: false,
      } as CaravelaHistoriaProps,

      servicos: {
        cards: responseServicos?.data?.data?.map((item) => {
          return {
            title: item?.nome || "",
            description: item?.descricao?.substring(0, 120) + "..." || "",
            img: {
              src: item?.imagem || "",
              alt: item.nome,
            },
            loading: false,
            link: {
              href: {
                pathname: "/servicos",
                query: {
                  id: item.id + "",
                },
              },
            },
          };
        }),
        title: "O que podemos fazer pela sua empresa!",
        mobile,
        loading: false,
      } as CaravelaEmpresaProps,

      produtos: {
        cards: responsePortifolio?.data?.data?.map((item) => {
          return {
            description: item?.descricao || "",
            title: item?.nome || "",
            img: {
              src: item?.imagem || "",
              alt: item?.nome || "",
            },
            link: {
              href: {
                pathname: `/detalhe-projeto/${item.slug}`,
              },
            },
            seta: {
              img: {
                src: "/img/icone/seta-dir-card.svg",
                alt: "seta",
                width: 16,
                height: 16,
              },
            },
            loading,
          };
        }),
        title: "Veja nossos projetos",
        seta: {
          src: "/img/icone/seta-dir-card.svg",
          alt: "seta",
          width: 16,
          height: 16,
        },
        loading,
        mobile,
        setaBaixo: "/img/icone/seta-baixo.svg",
      } as CaravelaProdutosProps,

      blog: {
        loading,
        mobile,
        cards: responseBlog?.data?.data?.splice(0, 6).map((item) => {
          return {
            description: item?.subtitulo || "",
            title: item?.titulo || "",
            tag: {
              title: item?.categoria.nome || "",
            },
            img: {
              src: item?.capa || "",
              alt: item?.subtitulo || "",
            },
            tempoLeitura: {
              date: "12.08.2020",
              time: "5 min",
              img: "/img/icone/relogio.svg",
            },
            link: {
              href: {
                pathname: `/blog/${item.slug}`,
              },
            },
            loading,
          };
        }),
        icone: "/img/icone/icone-blog.svg",
        seta: {
          src: "/img/icone/seta-dir-card.svg",
          alt: "seta",
          width: 16,
          height: 16,
        },
      } as CaravelaBlogProps,
    },
    revalidate: 60,
  };
};
